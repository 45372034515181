import React from 'react'

export const UsdcIcon = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
      fill="#2775CA"
    />
    <path
      clipRule="evenodd"
      d="M8.167 16.917c0 .25-.167.333-.417.333-3.083-1-5.25-3.833-5.25-7.167 0-3.333 2.167-6.166 5.25-7.166.25-.084.417.083.417.333v.583a.458.458 0 01-.25.417c-2.417.917-4.084 3.167-4.084 5.833 0 2.667 1.75 5 4.084 5.834.166.083.25.25.25.416v.584zm2.5-2.084a.359.359 0 01-.334.334h-.666a.358.358 0 01-.334-.334v-1c-1.333-.166-2-.916-2.25-2 0-.166.084-.333.25-.333h.75c.167 0 .25.083.334.25.166.583.5 1.083 1.583 1.083.833 0 1.417-.416 1.417-1.083s-.334-.917-1.5-1.083c-1.75-.25-2.584-.75-2.584-2.167 0-1.083.834-1.917 2-2.083v-1c0-.167.167-.334.334-.334h.666c.167 0 .334.167.334.334v1c1 .166 1.666.75 1.833 1.666 0 .167-.083.334-.25.334h-.667c-.166 0-.25-.084-.333-.25-.167-.584-.583-.834-1.333-.834-.834 0-1.25.417-1.25 1 0 .584.25.917 1.5 1.084 1.75.25 2.583.75 2.583 2.166 0 1.084-.833 2-2.083 2.25v1zm1.166 2.084c0 .25.167.416.417.333 3.083-1 5.25-3.833 5.25-7.167 0-3.333-2.25-6.166-5.25-7.166-.25 0-.417.083-.417.333v.583c0 .167.084.334.25.417 2.334.833 4.084 3.167 4.084 5.833 0 2.667-1.667 4.917-4.084 5.834a.458.458 0 00-.25.416v.584z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
)
