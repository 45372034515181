import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.svg`
  .fill {
    fill: ${(props) => props.theme.colors.mediumGrey};
  }
`

export const ExpandIcon: React.FC<{ className?: string }> = (props) => (
  <Wrapper
    className={`expandIcon ${props.className}`}
    height="14"
    viewBox="0 0 540.09 540.09"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M249.466 311.891l-20.349-20.35c-8.338-8.338-21.955-8.338-30.447 0L72.674 417.689v-107.1c0-11.857-9.715-21.496-21.497-21.496H22.49c-11.857 0-21.496 9.715-21.496 21.496v208.004c0 5.967 2.371 11.321 6.349 15.146 3.978 3.979 9.333 6.35 15.147 6.35h208.004c11.857 0 21.496-9.716 21.496-21.496v-28.688c0-11.857-9.715-21.497-21.496-21.497h-107.1l126.072-126.071c8.415-8.491 8.415-22.108 0-30.446zM532.746 6.35C528.768 2.372 523.412 0 517.598 0H309.596c-11.857 0-21.498 9.715-21.498 21.497v28.688c0 11.857 9.717 21.497 21.498 21.497h107.1L290.471 197.676c-8.34 8.339-8.34 22.032 0 30.447l20.348 20.349c8.34 8.339 22.033 8.339 30.447 0L467.414 122.4v107.1c0 11.857 9.717 21.497 21.496 21.497h28.688c11.857 0 21.498-9.716 21.498-21.497V21.573c-.078-6.044-2.526-11.399-6.35-15.223z"
    />
  </Wrapper>
)
