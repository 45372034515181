import React from 'react'

export const ChaiIcon = () => (
  <svg
    height="24px"
    preserveAspectRatio="xMidYMid meet"
    style={{ marginTop: '-5px' }}
    version="1.0"
    viewBox="0 0 3400 3400"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g fill="#a52639" id="layer101" stroke="none">
        <path d="M590 2940 l0 -60 -120 0 -120 0 0 -60 0 -60 360 0 360 0 0 -60 0 -60 -120 0 -120 0 0 -60 0 -60 -60 0 -60 0 0 -85 0 -85 -200 0 -200 0 0 -35 c0 -32 -2 -35 -30 -35 l-30 0 0 -360 0 -360 55 0 55 0 0 -35 0 -35 175 0 175 0 0 -85 c0 -50 -4 -85 -10 -85 -6 0 -10 -27 -10 -60 l0 -60 985 0 985 0 0 60 c0 47 -3 60 -15 60 -13 0 -15 67 -15 540 l0 540 -60 0 -60 0 0 60 0 60 -60 0 -60 0 0 60 0 60 -60 0 -60 0 0 60 0 60 345 0 345 0 0 60 0 60 -120 0 -120 0 0 60 0 60 -1065 0 -1065 0 0 -60z m120 -1020 l0 -300 -175 0 -175 0 0 300 0 300 175 0 175 0 0 -300z" />
      </g>
      <g fill="#dd3745" id="layer102" stroke="none">
        <path d="M1310 2820 l0 -60 825 0 825 0 0 60 0 60 -825 0 -825 0 0 -60z" />
        <path d="M1550 2580 l0 -60 -60 0 -60 0 0 -60 0 -60 -60 0 -60 0 0 -540 0 -540 -310 0 -310 0 0 -60 0 -60 985 0 985 0 0 60 c0 47 -3 60 -15 60 -13 0 -15 67 -15 540 l0 540 -60 0 -60 0 0 60 0 60 -60 0 -60 0 0 60 0 60 -420 0 -420 0 0 -60z" />
      </g>
      <g fill="#854cbf" id="layer103" stroke="none">
        <path d="M2520 2820 l0 -60 60 0 60 0 0 60 0 60 -60 0 -60 0 0 -60z" />
        <path d="M1890 2340 l0 -180 60 0 60 0 0 -420 0 -420 -660 0 -660 0 0 -60 0 -60 985 0 985 0 0 60 0 60 -265 0 -265 0 0 420 0 420 60 0 60 0 0 180 0 180 -180 0 -180 0 0 -180z" />
        <path d="M2370 1835 l0 -245 60 0 60 0 0 245 0 245 -60 0 -60 0 0 -245z" />
      </g>
      <g fill="#ffaa6e" id="layer104" stroke="none">
        <path d="M2520 2820 l0 -60 60 0 60 0 0 60 0 60 -60 0 -60 0 0 -60z" />
        <path d="M2370 1835 l0 -245 60 0 60 0 0 245 0 245 -60 0 -60 0 0 -245z" />
        <path d="M2010 1680 l0 -360 -660 0 -660 0 0 -60 0 -60 985 0 985 0 0 60 0 60 -265 0 -265 0 0 360 0 360 -60 0 -60 0 0 -360z" />
      </g>
      <g fill="#ffe091" id="layer105" stroke="none">
        <path d="M2520 2820 l0 -60 60 0 60 0 0 60 0 60 -60 0 -60 0 0 -60z" />
        <path d="M2370 1835 l0 -245 60 0 60 0 0 245 0 245 -60 0 -60 0 0 -245z" />
        <path d="M690 1260 l0 -60 660 0 660 0 0 60 0 60 -660 0 -660 0 0 -60z" />
        <path d="M2130 1260 l0 -60 265 0 265 0 0 60 0 60 -265 0 -265 0 0 -60z" />
      </g>
      <g fill="#cce2e1" id="layer106" stroke="none">
        <path d="M690 1260 l0 -60 660 0 660 0 0 60 0 60 -660 0 -660 0 0 -60z" />
        <path d="M2130 1260 l0 -60 265 0 265 0 0 60 0 60 -265 0 -265 0 0 -60z" />
      </g>
    </g>
  </svg>
)
