import React from 'react'

export const UsdtIcon = () => (
  <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0"
        fill="#53AE94"
      ></path>
      <path
        d="M11.234 8.668V7.18h3.403V4.914H5.37V7.18h3.402v1.488c-2.761.125-4.843.672-4.843 1.328s2.082 1.203 4.843 1.332v4.766h2.461v-4.766c2.762-.129 4.836-.676 4.836-1.332 0-.652-2.074-1.2-4.836-1.328m0 2.258v-.004c-.07.004-.425.027-1.218.027-.637 0-1.082-.02-1.239-.027v.004c-2.445-.11-4.265-.535-4.265-1.043 0-.508 1.82-.934 4.265-1.043v1.66c.16.012.618.04 1.25.04.758 0 1.137-.032 1.207-.04V8.84c2.438.11 4.258.535 4.258 1.043 0 .508-1.82.933-4.258 1.039"
        fill="#FFF"
      ></path>
    </g>
  </svg>
)
